<template>
    <div class="root-wrap">
        <div class="fx-fill fx-center-middle">
            <van-empty :image="require('@/assets/images/error/404.png')" description="您的页面丢失了"></van-empty>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>